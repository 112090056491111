@import '../../assets/scss/abstracts/abstracts';

#cargo-portal-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem;
    height: 5;
    box-shadow: -1px 0px 6px -2px #aaa;
    background: $white;
    @media (min-width: 992px) {
        margin-top: 53px;
    }
}

.atomic-paragraph{
   display: flex;
   flex-direction: row-reverse;
}

@media only screen and (max-width: 600px) {
    .navigation__copyright .navigation_language {
        width: 650px;
        float: left;
        padding: 5px;
    }
    .navigation__copyright .navigation_language .language {
        width: 300px;
        text-align: left;
        margin-left: 16px;
    }
    .navigation__copyright .navigation_language .navigation_links {
        width: 500px;
        text-align: left;
        margin-left: 16px;
    }
    .navigation__copyright .navigation_language .navigation_links_top {
        padding-top: 20px;
    }
    .navigation__copyright .navigation_language .navigation__link {
        text-decoration: none;
        color: rgb(124, 120, 120);
    }
    .atomic-paragraph-color {
        color: rgb(124, 120, 120);
    }
    .navigation__copyright .navigation_footer_logo {
        width: 650px;
        float: right;
    }
}

@media only screen and (min-width: 600px) {
    .navigation__copyright .navigation_language {
        width: 650px;
        float: left;
        padding: 5px;
    }
    .navigation__copyright .navigation_language .language {
        width: 300px;
        text-align: left;
        margin-left: 16px;
    }
    .navigation__copyright .navigation_language .navigation_links {
        width: 500px;
        text-align: left;
        margin-left: 16px;
    }
    .navigation__copyright .navigation_language .navigation_links_top {
        padding-top: 20px;
    }
    .navigation__copyright .navigation_language .navigation__link {
        text-decoration: none;
        color: rgb(124, 120, 120);
    }
    .atomic-paragraph-color {
        color: rgb(124, 120, 120);
    }
    .navigation__copyright .navigation_footer_logo {
        width: 650px;
        float: right;
    }
}

@media only screen and (min-width: 768px) {
    .navigation__copyright .navigation_language {
        width: 382px;
        float: left;
        padding: 5px;
    }
    .navigation__copyright .navigation_language .language {
        width: 300px;
        text-align: left;
        margin-left: 10px;
    }
    .navigation__copyright .navigation_language .navigation_links {
        width: 366px;
        text-align: left;
        margin-left: 10px;
    }
    .navigation__copyright .navigation_language .navigation_links_top {
        padding-top: 20px;
    }
    .navigation__copyright .navigation_language .navigation__link {
        text-decoration: none;
        color: rgb(124, 120, 120);
    }
    .atomic-paragraph-color {
        color: rgb(124, 120, 120);
    }
    .navigation__copyright .navigation_footer_logo {
        width: 359px;
        float: right;
    }
} 

@media only screen and (min-width: 992px) {
    .navigation__copyright .navigation_language {
        width: 558px;
        float: left;
        padding: 5px;
    }
    .navigation__copyright .navigation_language .language {
        width: 300px;
        text-align: left;
        margin-left: 20px;
    }
    .navigation__copyright .navigation_language .navigation_links {
        width: 500px;
        text-align: left;
        margin-left: 20px;
    }
    .navigation__copyright .navigation_language .navigation_links_top {
        padding-top: 20px;
    }
    .navigation__copyright .navigation_language .navigation__link {
        text-decoration: none;
        color: rgb(124, 120, 120);
    }
    .atomic-paragraph-color {
        color: rgb(124, 120, 120);
    }
    .navigation__copyright .navigation_footer_logo {
        width: 438px;
        float: right;
    }
} 

@media only screen and (min-width: 1200px) {
    .navigation__copyright .navigation_language {
        width: 650px;
        float: left;
        padding: 5px;
    }
    .navigation__copyright .navigation_language .language {
        width: 300px;
        text-align: left;
        margin-left: 100px;
    }
    .navigation__copyright .navigation_language .navigation_links {
        width: 500px;
        text-align: left;
        margin-left: 100px;
    }
    .navigation__copyright .navigation_language .navigation_links_top {
        padding-top: 20px;
    }
    .navigation__copyright .navigation_language .navigation__link {
        text-decoration: none;
        color: rgb(124, 120, 120);
    }
    .atomic-paragraph-color {
        color: rgb(124, 120, 120);
    }
    .navigation__copyright .navigation_footer_logo {
        width: 650px;
        float: right;
    }
}
